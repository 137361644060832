var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var binaryFeatures = {};

binaryFeatures.useBlobBuilder = function () {
  try {
    new Blob([]);
    return false;
  } catch (e) {
    return true;
  }
}();

binaryFeatures.useArrayBufferView = !binaryFeatures.useBlobBuilder && function () {
  try {
    return new Blob([new Uint8Array([])]).size === 0;
  } catch (e) {
    return true;
  }
}();

exports.binaryFeatures = binaryFeatures;
var BlobBuilder = exports.BlobBuilder;

if (typeof window !== "undefined") {
  BlobBuilder = exports.BlobBuilder = window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder || window.BlobBuilder;
}

function BufferBuilder() {
  (this || _global)._pieces = [];
  (this || _global)._parts = [];
}

BufferBuilder.prototype.append = function (data) {
  if (typeof data === "number") {
    (this || _global)._pieces.push(data);
  } else {
    this.flush();

    (this || _global)._parts.push(data);
  }
};

BufferBuilder.prototype.flush = function () {
  if ((this || _global)._pieces.length > 0) {
    var buf = new Uint8Array((this || _global)._pieces);

    if (!binaryFeatures.useArrayBufferView) {
      buf = buf.buffer;
    }

    (this || _global)._parts.push(buf);

    (this || _global)._pieces = [];
  }
};

BufferBuilder.prototype.getBuffer = function () {
  this.flush();

  if (binaryFeatures.useBlobBuilder) {
    var builder = new BlobBuilder();

    for (var i = 0, ii = (this || _global)._parts.length; i < ii; i++) {
      builder.append((this || _global)._parts[i]);
    }

    return builder.getBlob();
  } else {
    return new Blob((this || _global)._parts);
  }
};

exports.BufferBuilder = BufferBuilder;
export default exports;