import _bufferbuilder from "./bufferbuilder";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var BufferBuilder = _bufferbuilder.BufferBuilder;
var binaryFeatures = _bufferbuilder.binaryFeatures;
var BinaryPack = {
  unpack: function (data) {
    var unpacker = new Unpacker(data);
    return unpacker.unpack();
  },
  pack: function (data) {
    var packer = new Packer();
    packer.pack(data);
    var buffer = packer.getBuffer();
    return buffer;
  }
};
exports = BinaryPack;

function Unpacker(data) {
  // Data is ArrayBuffer
  (this || _global).index = 0;
  (this || _global).dataBuffer = data;
  (this || _global).dataView = new Uint8Array((this || _global).dataBuffer);
  (this || _global).length = (this || _global).dataBuffer.byteLength;
}

Unpacker.prototype.unpack = function () {
  var type = this.unpack_uint8();

  if (type < 128) {
    return type;
  } else if ((type ^ 224) < 32) {
    return (type ^ 224) - 32;
  }

  var size;

  if ((size = type ^ 160) <= 15) {
    return this.unpack_raw(size);
  } else if ((size = type ^ 176) <= 15) {
    return this.unpack_string(size);
  } else if ((size = type ^ 144) <= 15) {
    return this.unpack_array(size);
  } else if ((size = type ^ 128) <= 15) {
    return this.unpack_map(size);
  }

  switch (type) {
    case 192:
      return null;

    case 193:
      return undefined;

    case 194:
      return false;

    case 195:
      return true;

    case 202:
      return this.unpack_float();

    case 203:
      return this.unpack_double();

    case 204:
      return this.unpack_uint8();

    case 205:
      return this.unpack_uint16();

    case 206:
      return this.unpack_uint32();

    case 207:
      return this.unpack_uint64();

    case 208:
      return this.unpack_int8();

    case 209:
      return this.unpack_int16();

    case 210:
      return this.unpack_int32();

    case 211:
      return this.unpack_int64();

    case 212:
      return undefined;

    case 213:
      return undefined;

    case 214:
      return undefined;

    case 215:
      return undefined;

    case 216:
      size = this.unpack_uint16();
      return this.unpack_string(size);

    case 217:
      size = this.unpack_uint32();
      return this.unpack_string(size);

    case 218:
      size = this.unpack_uint16();
      return this.unpack_raw(size);

    case 219:
      size = this.unpack_uint32();
      return this.unpack_raw(size);

    case 220:
      size = this.unpack_uint16();
      return this.unpack_array(size);

    case 221:
      size = this.unpack_uint32();
      return this.unpack_array(size);

    case 222:
      size = this.unpack_uint16();
      return this.unpack_map(size);

    case 223:
      size = this.unpack_uint32();
      return this.unpack_map(size);
  }
};

Unpacker.prototype.unpack_uint8 = function () {
  var byte = (this || _global).dataView[(this || _global).index] & 255;
  (this || _global).index++;
  return byte;
};

Unpacker.prototype.unpack_uint16 = function () {
  var bytes = this.read(2);
  var uint16 = (bytes[0] & 255) * 256 + (bytes[1] & 255);
  (this || _global).index += 2;
  return uint16;
};

Unpacker.prototype.unpack_uint32 = function () {
  var bytes = this.read(4);
  var uint32 = ((bytes[0] * 256 + bytes[1]) * 256 + bytes[2]) * 256 + bytes[3];
  (this || _global).index += 4;
  return uint32;
};

Unpacker.prototype.unpack_uint64 = function () {
  var bytes = this.read(8);
  var uint64 = ((((((bytes[0] * 256 + bytes[1]) * 256 + bytes[2]) * 256 + bytes[3]) * 256 + bytes[4]) * 256 + bytes[5]) * 256 + bytes[6]) * 256 + bytes[7];
  (this || _global).index += 8;
  return uint64;
};

Unpacker.prototype.unpack_int8 = function () {
  var uint8 = this.unpack_uint8();
  return uint8 < 128 ? uint8 : uint8 - (1 << 8);
};

Unpacker.prototype.unpack_int16 = function () {
  var uint16 = this.unpack_uint16();
  return uint16 < 32768 ? uint16 : uint16 - (1 << 16);
};

Unpacker.prototype.unpack_int32 = function () {
  var uint32 = this.unpack_uint32();
  return uint32 < Math.pow(2, 31) ? uint32 : uint32 - Math.pow(2, 32);
};

Unpacker.prototype.unpack_int64 = function () {
  var uint64 = this.unpack_uint64();
  return uint64 < Math.pow(2, 63) ? uint64 : uint64 - Math.pow(2, 64);
};

Unpacker.prototype.unpack_raw = function (size) {
  if ((this || _global).length < (this || _global).index + size) {
    throw new Error("BinaryPackFailure: index is out of range" + " " + (this || _global).index + " " + size + " " + (this || _global).length);
  }

  var buf = (this || _global).dataBuffer.slice((this || _global).index, (this || _global).index + size);

  (this || _global).index += size; // buf = util.bufferToString(buf);

  return buf;
};

Unpacker.prototype.unpack_string = function (size) {
  var bytes = this.read(size);
  var i = 0;
  var str = "";
  var c;
  var code;

  while (i < size) {
    c = bytes[i];

    if (c < 128) {
      str += String.fromCharCode(c);
      i++;
    } else if ((c ^ 192) < 32) {
      code = (c ^ 192) << 6 | bytes[i + 1] & 63;
      str += String.fromCharCode(code);
      i += 2;
    } else {
      code = (c & 15) << 12 | (bytes[i + 1] & 63) << 6 | bytes[i + 2] & 63;
      str += String.fromCharCode(code);
      i += 3;
    }
  }

  (this || _global).index += size;
  return str;
};

Unpacker.prototype.unpack_array = function (size) {
  var objects = new Array(size);

  for (var i = 0; i < size; i++) {
    objects[i] = this.unpack();
  }

  return objects;
};

Unpacker.prototype.unpack_map = function (size) {
  var map = {};

  for (var i = 0; i < size; i++) {
    var key = this.unpack();
    var value = this.unpack();
    map[key] = value;
  }

  return map;
};

Unpacker.prototype.unpack_float = function () {
  var uint32 = this.unpack_uint32();
  var sign = uint32 >> 31;
  var exp = (uint32 >> 23 & 255) - 127;
  var fraction = uint32 & 8388607 | 8388608;
  return (sign === 0 ? 1 : -1) * fraction * Math.pow(2, exp - 23);
};

Unpacker.prototype.unpack_double = function () {
  var h32 = this.unpack_uint32();
  var l32 = this.unpack_uint32();
  var sign = h32 >> 31;
  var exp = (h32 >> 20 & 2047) - 1023;
  var hfrac = h32 & 1048575 | 1048576;
  var frac = hfrac * Math.pow(2, exp - 20) + l32 * Math.pow(2, exp - 52);
  return (sign === 0 ? 1 : -1) * frac;
};

Unpacker.prototype.read = function (length) {
  var j = (this || _global).index;

  if (j + length <= (this || _global).length) {
    return (this || _global).dataView.subarray(j, j + length);
  } else {
    throw new Error("BinaryPackFailure: read index out of range");
  }
};

function Packer() {
  (this || _global).bufferBuilder = new BufferBuilder();
}

Packer.prototype.getBuffer = function () {
  return (this || _global).bufferBuilder.getBuffer();
};

Packer.prototype.pack = function (value) {
  var type = typeof value;

  if (type === "string") {
    this.pack_string(value);
  } else if (type === "number") {
    if (Math.floor(value) === value) {
      this.pack_integer(value);
    } else {
      this.pack_double(value);
    }
  } else if (type === "boolean") {
    if (value === true) {
      (this || _global).bufferBuilder.append(195);
    } else if (value === false) {
      (this || _global).bufferBuilder.append(194);
    }
  } else if (type === "undefined") {
    (this || _global).bufferBuilder.append(192);
  } else if (type === "object") {
    if (value === null) {
      (this || _global).bufferBuilder.append(192);
    } else {
      var constructor = value.constructor;

      if (constructor == Array) {
        this.pack_array(value);
      } else if (constructor == Blob || constructor == File || value instanceof Blob || value instanceof File) {
        this.pack_bin(value);
      } else if (constructor == ArrayBuffer) {
        if (binaryFeatures.useArrayBufferView) {
          this.pack_bin(new Uint8Array(value));
        } else {
          this.pack_bin(value);
        }
      } else if ("BYTES_PER_ELEMENT" in value) {
        if (binaryFeatures.useArrayBufferView) {
          this.pack_bin(new Uint8Array(value.buffer));
        } else {
          this.pack_bin(value.buffer);
        }
      } else if (constructor == Object || constructor.toString().startsWith("class")) {
        this.pack_object(value);
      } else if (constructor == Date) {
        this.pack_string(value.toString());
      } else if (typeof value.toBinaryPack === "function") {
        (this || _global).bufferBuilder.append(value.toBinaryPack());
      } else {
        throw new Error("Type \"" + constructor.toString() + "\" not yet supported");
      }
    }
  } else {
    throw new Error("Type \"" + type + "\" not yet supported");
  }

  (this || _global).bufferBuilder.flush();
};

Packer.prototype.pack_bin = function (blob) {
  var length = blob.length || blob.byteLength || blob.size;

  if (length <= 15) {
    this.pack_uint8(160 + length);
  } else if (length <= 65535) {
    (this || _global).bufferBuilder.append(218);

    this.pack_uint16(length);
  } else if (length <= 4294967295) {
    (this || _global).bufferBuilder.append(219);

    this.pack_uint32(length);
  } else {
    throw new Error("Invalid length");
  }

  (this || _global).bufferBuilder.append(blob);
};

Packer.prototype.pack_string = function (str) {
  var length = utf8Length(str);

  if (length <= 15) {
    this.pack_uint8(176 + length);
  } else if (length <= 65535) {
    (this || _global).bufferBuilder.append(216);

    this.pack_uint16(length);
  } else if (length <= 4294967295) {
    (this || _global).bufferBuilder.append(217);

    this.pack_uint32(length);
  } else {
    throw new Error("Invalid length");
  }

  (this || _global).bufferBuilder.append(str);
};

Packer.prototype.pack_array = function (ary) {
  var length = ary.length;

  if (length <= 15) {
    this.pack_uint8(144 + length);
  } else if (length <= 65535) {
    (this || _global).bufferBuilder.append(220);

    this.pack_uint16(length);
  } else if (length <= 4294967295) {
    (this || _global).bufferBuilder.append(221);

    this.pack_uint32(length);
  } else {
    throw new Error("Invalid length");
  }

  for (var i = 0; i < length; i++) {
    this.pack(ary[i]);
  }
};

Packer.prototype.pack_integer = function (num) {
  if (num >= -32 && num <= 127) {
    (this || _global).bufferBuilder.append(num & 255);
  } else if (num >= 0 && num <= 255) {
    (this || _global).bufferBuilder.append(204);

    this.pack_uint8(num);
  } else if (num >= -128 && num <= 127) {
    (this || _global).bufferBuilder.append(208);

    this.pack_int8(num);
  } else if (num >= 0 && num <= 65535) {
    (this || _global).bufferBuilder.append(205);

    this.pack_uint16(num);
  } else if (num >= -32768 && num <= 32767) {
    (this || _global).bufferBuilder.append(209);

    this.pack_int16(num);
  } else if (num >= 0 && num <= 4294967295) {
    (this || _global).bufferBuilder.append(206);

    this.pack_uint32(num);
  } else if (num >= -2147483648 && num <= 2147483647) {
    (this || _global).bufferBuilder.append(210);

    this.pack_int32(num);
  } else if (num >= -9223372036854776000 && num <= 9223372036854776000) {
    (this || _global).bufferBuilder.append(211);

    this.pack_int64(num);
  } else if (num >= 0 && num <= 18446744073709552000) {
    (this || _global).bufferBuilder.append(207);

    this.pack_uint64(num);
  } else {
    throw new Error("Invalid integer");
  }
};

Packer.prototype.pack_double = function (num) {
  var sign = 0;

  if (num < 0) {
    sign = 1;
    num = -num;
  }

  var exp = Math.floor(Math.log(num) / Math.LN2);
  var frac0 = num / Math.pow(2, exp) - 1;
  var frac1 = Math.floor(frac0 * Math.pow(2, 52));
  var b32 = Math.pow(2, 32);
  var h32 = sign << 31 | exp + 1023 << 20 | frac1 / b32 & 1048575;
  var l32 = frac1 % b32;

  (this || _global).bufferBuilder.append(203);

  this.pack_int32(h32);
  this.pack_int32(l32);
};

Packer.prototype.pack_object = function (obj) {
  var keys = Object.keys(obj);
  var length = keys.length;

  if (length <= 15) {
    this.pack_uint8(128 + length);
  } else if (length <= 65535) {
    (this || _global).bufferBuilder.append(222);

    this.pack_uint16(length);
  } else if (length <= 4294967295) {
    (this || _global).bufferBuilder.append(223);

    this.pack_uint32(length);
  } else {
    throw new Error("Invalid length");
  }

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      this.pack(prop);
      this.pack(obj[prop]);
    }
  }
};

Packer.prototype.pack_uint8 = function (num) {
  (this || _global).bufferBuilder.append(num);
};

Packer.prototype.pack_uint16 = function (num) {
  (this || _global).bufferBuilder.append(num >> 8);

  (this || _global).bufferBuilder.append(num & 255);
};

Packer.prototype.pack_uint32 = function (num) {
  var n = num & 4294967295;

  (this || _global).bufferBuilder.append((n & 4278190080) >>> 24);

  (this || _global).bufferBuilder.append((n & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((n & 65280) >>> 8);

  (this || _global).bufferBuilder.append(n & 255);
};

Packer.prototype.pack_uint64 = function (num) {
  var high = num / Math.pow(2, 32);
  var low = num % Math.pow(2, 32);

  (this || _global).bufferBuilder.append((high & 4278190080) >>> 24);

  (this || _global).bufferBuilder.append((high & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((high & 65280) >>> 8);

  (this || _global).bufferBuilder.append(high & 255);

  (this || _global).bufferBuilder.append((low & 4278190080) >>> 24);

  (this || _global).bufferBuilder.append((low & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((low & 65280) >>> 8);

  (this || _global).bufferBuilder.append(low & 255);
};

Packer.prototype.pack_int8 = function (num) {
  (this || _global).bufferBuilder.append(num & 255);
};

Packer.prototype.pack_int16 = function (num) {
  (this || _global).bufferBuilder.append((num & 65280) >> 8);

  (this || _global).bufferBuilder.append(num & 255);
};

Packer.prototype.pack_int32 = function (num) {
  (this || _global).bufferBuilder.append(num >>> 24 & 255);

  (this || _global).bufferBuilder.append((num & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((num & 65280) >>> 8);

  (this || _global).bufferBuilder.append(num & 255);
};

Packer.prototype.pack_int64 = function (num) {
  var high = Math.floor(num / Math.pow(2, 32));
  var low = num % Math.pow(2, 32);

  (this || _global).bufferBuilder.append((high & 4278190080) >>> 24);

  (this || _global).bufferBuilder.append((high & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((high & 65280) >>> 8);

  (this || _global).bufferBuilder.append(high & 255);

  (this || _global).bufferBuilder.append((low & 4278190080) >>> 24);

  (this || _global).bufferBuilder.append((low & 16711680) >>> 16);

  (this || _global).bufferBuilder.append((low & 65280) >>> 8);

  (this || _global).bufferBuilder.append(low & 255);
};

function _utf8Replace(m) {
  var code = m.charCodeAt(0);
  if (code <= 2047) return "00";
  if (code <= 65535) return "000";
  if (code <= 2097151) return "0000";
  if (code <= 67108863) return "00000";
  return "000000";
}

function utf8Length(str) {
  if (str.length > 600) {
    // Blob method faster for large strings
    return new Blob([str]).size;
  } else {
    return str.replace(/[^\u0000-\u007F]/g, _utf8Replace).length;
  }
}

export default exports;